import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import ContentBox from 'components/Shared/ContentBox/ContentBox';
import SeparatorWithGradient from 'components/Shared/SeparatorWithGradient/SeparatorWithGradient';
import './FaqContainer.scss';

const FAQContainer = ({ data }) => (
  <div className="aaa-faq">
    <ContentBox>
      <h2 className="aaa-faq__title">{data.title}</h2>
      <p className="aaa-faq__description">{data.description}</p>
      <Accordion allowZeroExpanded={true}>
        {data.faqs.map((item, i) => (
          <AccordionItem key={i}>
            <AccordionItemHeading>
              <AccordionItemButton>
                <span className="aaa-faq__question">{item.question}</span>
                <span className="aaa-faq__caret">&rsaquo;</span>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div
                className="aaa-faq__answer"
                dangerouslySetInnerHTML={{
                  __html: item.answer,
                }}
              />
              <SeparatorWithGradient />
            </AccordionItemPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </ContentBox>
  </div>
);

export default FAQContainer;
