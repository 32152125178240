import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/Layout/Layout';
import SEO from 'components/SEO/SEO';
import ZipGateContainer from 'components/ZipGateContainer/ZipGateContainer';
import Hero from 'components/Hero/Hero';
import FAQContainer from 'components/FaqContainer/FaqContainer';
import PreFooter from 'components/PreFooter/PreFooter';

const FAQPage = ({ data, location }) => {
  return (
    <Layout location={location} type="standard">
      <SEO title={data.faqsYaml.title} />
      <Hero data={data.faqsYaml.hero} />
      <FAQContainer data={data.faqsYaml.faqContainer} />
      <PreFooter />
      <ZipGateContainer location={location} />
    </Layout>
  );
};

export default FAQPage;

export const pageQuery = graphql`
  query FAQPageQuery {
    faqsYaml {
      id
      title

      hero {
        size
        title
        heroImage {
          childImageSharp {
            fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        separator
      }
      faqContainer {
        title
        description
        faqs {
          question
          answer
        }
      }
    }
  }
`;
